import React, { Fragment } from "react";
import Header from "../components/Header";
import Archives from "../components/Archives";
import Footer from "../components/Footer";
import Typekit from "../components/Typekit";
import { Helmet } from "react-helmet";
import "../styl/main.styl";

const DefaultLayout = (props) => (
  <Fragment>
    <Helmet>
      <link rel="shortcut icon" href="/images/simplewebrtc-icon.png" />
    </Helmet>
    <Typekit id="gqk8csn" />
    <Header className={props.headerClassName} />
    <div className="container">
      <div className="content" role="main">
        {props.children}
      </div>
    </div>
    <Archives archiveYears={props.archiveYears} />
    <Footer />
  </Fragment>
);

export default DefaultLayout;
