import React from "react";

const Footer = () => (
  <footer role="contentinfo">
    <div className="container">
      <section className="cf">
        <a
          href="/"
          title="&amp;yet's Home Page"
          className="logo ampersand-logo transition transition-fadeout pull-left"
        >
          &yet
        </a>
        <nav role="navigation">
          <ul className="list-inline list-unstyled">
            <li>
              <a href="https://andyet.com/webrtc" className="external">Consulting</a>•
            </li>
            <li>
              <a href="//simplewebrtc.com/pricing">Pricing</a>•
            </li>
            <li>
              <a href="//simplewebrtc.com/faq">FAQ</a>•
            </li>
            <li>
              <a href="http://docs.simplewebrtc.com">Docs</a>•
            </li>
            <li>
              <a href="//simplewebrtc.com/newsletter">Newsletter</a>•
            </li>
            <li>
              <a href="//simplewebrtc.com/contact">Contact</a>
            </li>
          </ul>
          <ul className="list-inline list-unstyled">
            <li>
              <a href="//simplewebrtc.com/terms-of-service">Terms of Service</a>•
            </li>
            <li>
              <a href="//simplewebrtc.com/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </nav>
      </section>
    </div>
    <a href="https://andyet.com">
      <div className="logo andyet-logo">&yet</div>
      <p>Made by your friends at &yet.</p>
    </a>
  </footer>
);

export default Footer;
