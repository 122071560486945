import React from "react";

const Header = (props) => (
  <header className={props.className} role="banner">
    <div id="nav" className="menu">
      <div className="navigation header-has-logo">
        <div className="cf">
          <a
            href="//simplewebrtc.com"
            className="logo pull-left"
          >
            <img src="/images/logo-simplewebrtc.svg" className="swrtc-logo" alt="SimpleWebRTC" />
          </a>
          <nav role="navigation" className="pull-right top-nav top-nav-light">
            <input id="menu-toggle" type="checkbox" className="menu-toggle" />
            <label htmlFor="menu-toggle" className="pull-right">
              Menu
            </label>
            <ul className="list-unstyled list-inline no-margin-bottom cf">
              <li>
                <a href="//simplewebrtc.com/pricing">Pricing</a>
              </li>
              <li>
                <a href="//simplewebrtc.com/faq">FAQ</a>
              </li>
              <li>
                <a href="http://docs.simplewebrtc.com">Docs</a>
              </li>
              <li>
                <a href="//andyet.com/webrtc" className="external">Consulting</a>
              </li>
              <li>
                <a href="//simplewebrtc.com/newsletter">Newsletter</a>
              </li>
              <li>
                <a href="//simplewebrtc.com/contact">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div className="container banner">
      <img src="/images/webrtc-bolt.png" className="bolt-icon" alt="lightning bolt" />
      <h1>Keeping It Realtime</h1>
      <p>WebRTC news and thoughts from the SimpleWebRTC + Talky team</p>
    </div>
  </header>
);

export default Header;
